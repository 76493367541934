import { FC } from "react"
// Components
import { FooterWrapper, PolicyText } from "./index.styles"
import { Text } from "@components/common"
// Libs
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
// Styles

const Footer: FC = () => {
  const { t } = useTranslation(["common"])
  const router = useRouter()

  const handleClickLink = (type: "policy" | "terms") => {
    router.push({
      pathname: `/${type}`,
      query: { from: "footer" }
    })
  }

  return (
    <FooterWrapper>
      <PolicyText onClick={() => handleClickLink("policy")}>{t("POLICY_AND_TERMS_Label_Privacy_Policy")}</PolicyText>
      <PolicyText onClick={() => handleClickLink("terms")}>{t("POLICY_AND_TERMS_Label_Terms_Of_Service")}</PolicyText>
      <Text color="black3">{t("FOOTER_copy_right")}</Text>
    </FooterWrapper>
  )
}

export default Footer
